import Vue from 'vue'
import Router from 'vue-router'


Vue.use(Router)

const router = new Router({
  base: process.env.VUE_APP_PUBLICPATH,
  mode: 'history',
  routes: [
    {
      path: '/',
      alias: '/Exceptions',
      meta: {
        title: 'Explorar Excepciones',
        requiresAuth: true
      },
      
      component: () => import('./views/private/Exceptions.vue')
    },
    {
      path: '/OperationExecutions',
      meta: {
        title: 'Explorar Operaciones',
        requiresAuth: true
      },
      
      component: () => import('./views/private/OperationExecutions.vue')
    },
    {
      path: '/KPIs',
      meta: {
        title: 'Consultar KPIs',
        requiresAuth: true
      },
      
      component: () => import('./views/private/KPIs.vue')
    }/*,
    {
      path: "*",
      meta: { IsNotFound: true }
    }*/,
    {
      path: "/Health",
      meta: { IsHealthCheck: true }
    }
  ]
});


router.beforeEach (VueUtils.RouterBeforeEach);


export default router;