import Vue from 'vue'
import { FormDatepickerPlugin, FormInputPlugin, FormSelectPlugin, ButtonPlugin, DropdownPlugin, PaginationPlugin, FormCheckboxPlugin, TablePlugin } from 'bootstrap-vue'
import App from './App.vue'
import router from './router'
import store from './store/store'
import SimpleVueValidation from 'simple-vue-validator';
import axios from 'axios';


Vue.use(SimpleVueValidation);

Vue.use(FormDatepickerPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormSelectPlugin);
Vue.use(ButtonPlugin);
Vue.use(DropdownPlugin);
Vue.use(PaginationPlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(TablePlugin);


SimpleVueValidation.extendTemplates(VueUtils.SimpleVueValidationMessages);

window.addEventListener("load", 
  function () { 
    window.SetAxiosSettings (axios); 
  });

Vue.prototype.$http = axios;

Vue.mixin({
  methods: {
    FormatDateTime: pDateTime => FormatDateTime(pDateTime),
    FormatDate: pDate => FormatDate(pDate)
  },

  data() {
    return { User: User }
  },

  mounted() {

    this.ApplicationName = "ESF.Trace.WebFrontEnd";

    this.ValidateNested = pOKFunction => VueUtils.ValidateNested(this, pOKFunction);
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');